<template>
  <div class="mr6-dk ml6-dk text-end page-container" v-if="!loading">
    <div class="display-flex justify-end">
      <div class="pr1-2">
        <bh-select
          v-if="selectedUsers.length > 1"
          placeholder="Choose Option"
          class="align-center br0-5 f1-5 pv1 border-violet pointer z999"
          :options="selectOptions"
          @output="updateActivation"
          />
      </div>
      <div class="display-flex">
        <bh-button @output="toggleModal" class="display-flex align-center mb2" btnClass="bg-violet text-white border-none br0-5 f1-5 pa1">
        <bh-icon icon="plus" class="mr2" />
        <span class="mt0-2">Add New User</span>
      </bh-button>
      </div>
    </div>
    <bh-modal-overlay :showModal="showModal" @modal-close="toggleModal">
      <div class="w40vw-dk text-center bg-white w98vw">
        <div class="display-flex justify-between align-center f2 pv2 border-bottom ph2">
          <div>
            <span>Add User</span>
            <span class="ml1 text-danger text-left f1-3" v-if="errorMessage">
              {{ errorMessage }}
            </span>
          </div>
          <bh-icon class="pointer f1-5" icon="cross" @output.self="toggleModal"/>
        </div>
        <div class="display-flex-dk justify-between w100p pa2-dk">
          <div class="w40p-dk text-left pa1-mb">
            <label class="f1-2">First Name</label>
            <bh-input
              class="pa0-5 br0-5 border-grey"
              :max="15"
              placeholder="Enter First Name"
              :value="user.firstName"
              @output="updateValue(user, 'firstName', $event)"
              required
              />
          </div>
          <div class="w40p-dk text-left pa1-mb">
            <label class="f1-2">Last Name</label>
            <bh-input
              class="pa0-5 br0-5 border-grey"
              placeholder="Enter Last Name"
              :max="15"
              :value="user.lastName"
              @output="updateValue(user, 'lastName', $event)"
              required
              />
          </div>
        </div>
        <div class="display-flex-dk justify-between w100p pa2-dk">
          <div class="w40p-dk text-left pa1-mb">
            <label class="f1-2">Email ID</label>
            <bh-input
              class="pa0-5 br0-5 border-grey pos-rel"
              placeholder="Enter Email"
              type="email"
              :value="user.email"
              @output="updateValue(user, 'email', $event)"
              @keypress.enter="saveUser"
              required
              >
            </bh-input>
          </div>
          <div class="w40p-dk text-left pa1-mb">
            <label class="f1-2">Role Name</label>
            <bh-select
              class="pa0-5 br0-5 border-grey text-left f1-5"
              :showIcon="false"
              :options="roles"
              placeholder="Select a role"
              :value="user.role"
              @output="updateValue(user, 'role', $event)" />
          </div>
        </div>
        <bh-button
          btnClass="bg-violet text-white border-none br0-5 mt3 f1-5 pa1 mb2 w30p"
          :disabled="!isUserDetailsValid"
          @output="saveUser"
          >
          <bh-loader v-if="saving" color="#ffffff" :size="20" :width="2"/>
          <span v-else>Add</span>
        </bh-button>
      </div>
    </bh-modal-overlay>
    <bh-table
      class="datatable-container w100p overflow-auto maxh65 text-wrap box-shadow box-shadow-light-dk maxh40-mb"
      cellClass="text-left text-center-mb text-wrap"
      headerClass="bg-violet-light text-center text-center-mb pos-rel w50p-mb w25p"
      enableSearch
      enableCheckbox
      :enableSort="false"
      :spinner="fetchingUsers"
      searchPlaceholder="Search Users"
      :title="`Users (${getRows.length})`"
      firstColumnAlignLeft
      :rows="getRows"
      :headers="headers"
      sticky
      @onRowSelect="onSelect"
      @search="onSearch"
      :tableLength="users.length"
      :firstRowHighlight="true"
      >
      <template v-slot:active="cell">
        <div class="display-flex justify-center text-left">
          <bh-button
            v-if="cell.data.originalValue !== userId"
            :btnClass="`br0-5 pa1 wauto ${cell.data.value ? 'border-danger text-danger' : 'bg-violet text-white'} minw14`"
            @output="toggleActivate(cell.data)"
            >
            <bh-loader v-if="cell.data.originalValue === savingUserId" :color="cell.data.value ? '#e90809' : '#ffffff'" :size="18" :width="1.5" />
            <span v-else>{{ cell.data.value ? 'Deactivate' : 'Activate' }}</span>
          </bh-button>
        </div>
      </template>
    </bh-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { User } from '@/services/user'
import { validateEmail, validateName, updateValue } from '@/utils'
import moment from '@/utils/moment'

export default {
  name: 'tbh-settings',
  data () {
    return {
      searchTerm: '',
      fetchingUsers: false,
      showModal: false,
      headers: {
        name: 'Name',
        email: 'Email',
        created_on: 'Joined On',
        last_logged_in: 'Last Logged In',
        active: ''
      },
      confirmationModal: {
        confirmButtonLabel: 'Add',
        show: false,
        title: '',
        subTitle: ''
      },
      users: [],
      user: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        active: true
      },
      roles: [
        { label: 'Admin', value: 'admin' },
        { label: 'User', value: 'user' }
      ],
      saving: false,
      savingUserId: null,
      errorMessage: '',
      selectOptions: [
        { label: 'Activate', value: 'activate' },
        { label: 'Deactivate', value: 'deactivate' }
      ],
      selectedUsers: []
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('common/user', ['userId']),
    /**
     * Usefull while filtering data
    */
    getRows() {
      if (!this.searchTerm) return this.users
      return this.users.filter(({ name, email }) => {
        const includesInName = (`${name?.value}`).toLowerCase().includes(this.searchTerm.toLowerCase())
        const includesInEmail = email?.value?.toLowerCase().includes(this.searchTerm.toLowerCase())
        return includesInName || includesInEmail
      })
    },
    isUserDetailsValid() {
      const { firstName, lastName, email, role } = this.user
      return validateName(firstName) && validateName(lastName) && validateEmail(email) && Boolean(role)
    }
  },
  async mounted() {
    /**
     * To fetch the list of users on page load
    */
    this.fetchingUsers = true
    const users = await User.getAllUsers()
    const userList = users.map(this.prepareUser)
    const activeUserDetails = userList.filter(x => x.active.originalValue === this.userId)
    const filteredUserList = userList.filter(x => x.active.originalValue !== this.userId)
    this.users = [...activeUserDetails, ...filteredUserList]
    this.fetchingUsers = false
  },
  watch: {
    'user.firstName'(value) {
      this.errorMessage = null
      if (!validateName(value)) this.errorMessage = 'Enter valid first name'
    },
    'user.lastName'(value) {
      this.errorMessage = null
      if (!validateName(value)) this.errorMessage = 'Enter valid last name'
    },
    'user.email'(value) {
      this.errorMessage = null
      if (this.saving) return
      if (value && !validateEmail(value)) this.errorMessage = 'Enter valid email'
    }
  },
  methods: {
    ...mapActions(['setSingleProperty']),
    updateValue,
    /**
     * Callback for search
     * @method onSearch
     * @param - searchTerm - { String }
    */
    onSearch(searchTerm) {
      this.searchTerm = searchTerm
    },
    /**
     * Callback for select
     * @method onSelect
     * @param - selectedUser - { object }
     */
    onSelect(selectedUser) {
      if (selectedUser.checked) {
        this.selectedUsers.push(selectedUser)
      } else {
        this.selectedUsers = [...this.selectedUsers.filter(user => user.email.value !== selectedUser.email.value)]
      }
    },
    /**
     * Update Activation for selected users
     * @method updateActivation
     */
    updateActivation(value) {
      if (value === 'activate') {
        const deactivatedSelectedUsers = this.selectedUsers.filter(users => !users.active.value)
        deactivatedSelectedUsers.forEach(user => this.toggleActivate(user.active))
      } else if (value === 'deactivate') {
        const deactivatedSelectedUsers = this.selectedUsers.filter(users => users.active.value)
        deactivatedSelectedUsers.forEach(user => this.toggleActivate(user.active))
      }
    },
    /**
     * toggles modal
    */
    toggleModal() {
      this.showModal = !this.showModal
      if (!this.showModal) {
        this.errorMessage = ''
        this.user = {
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          active: true
        }
      }
    },

    /**
     * @method prepareUser
     * Prepare users for table reference
    */
    prepareUser(user) {
      return {
        checked: false,
        name: {
          value: `${user.firstName} ${user.lastName}`
        },
        email: {
          value: user.email
        },
        created_on: {
          value: user?.created_on ? moment.fromNow(user.created_on) : 'NA'
        },
        last_logged_in: {
          value: user?.last_logged_in ? moment.fromNow(user?.last_logged_in) : 'NA'
        },
        active: {
          value: user.active,
          originalValue: user.id
        }
      }
    },

    /**
     * @method saveUser
     * Saves user into system
    */
    async saveUser() {
      if (!this.isUserDetailsValid) {
        this.errorMessage = 'Enter valid details'
        return
      }
      this.saving = true
      try {
        await User.save(this.user)
        // Push it list once user has been created
        this.users.unshift(this.prepareUser(this.user))
        this.user = { firstName: '', lastName: '', email: '', active: true }
        this.showConfirmation({ message: 'User has been created successfully.' })
        this.toggleModal()
      } catch (err) {
        const message = err?.error?.details?.messages?.email?.[0] || err?.error?.message
        this.showConfirmation({ message, color: '#F44336' })
      }
      this.saving = false
      // should be clear after next iteration
      setTimeout(() => {
        this.errorMessage = null
      })
    },

    /**
     * @method toggleActivate
     * Activates/Deactives the user
    */
    async toggleActivate(data) {
      const { originalValue, value } = data
      if (!originalValue) return
      try {
        this.savingUserId = originalValue
        await User.updateById(originalValue, { active: !value })
        data.value = !value
        this.showConfirmation({ message: `User has been ${!value ? 'Activated' : 'Deactivated'}` })
      } catch {
        this.showConfirmation({ color: '#F44336' })
      }
      this.savingUserId = null
    },

    /**
     * @method showConfirmation
     * shows confirmation after process
    */
    showConfirmation({ message = 'Something went wrong while creating user, please try agian.', color }) {
      const toast = { show: true, message, color }
      this.setSingleProperty(['toast', toast])
    }
  }
}
</script>
